import React from 'react';
import './FooterStyles.css';
// import { FaPhone, FaSearchLocation } from 'react-icons/fa';

const Footer = () => {
  const date = new Date().getFullYear();
  return (
    <div className='footer'>
      <div className='footer-container'>
        <div className='left'>
          <h2>CNW Technologies</h2>
          <a href='https://cnwconsultants.com' target='_blank' rel='noreferrer'>
            <span>a division of CNW Consultants LLC</span>
          </a>
          <div>
            <div className='phone'>
              {/* <FaPhone
                size={20}
                style={{ color: '#ffffff', marginRight: '2rem' }}
              /> */}
              (609) 890-0117
            </div>
          </div>

          <div className='email'>
            <a href='mailto:info@cnwconsultants.com'>
              <p id='email'>info@cnwconsultants.com</p>
            </a>
          </div>

          {/* <div className="phone">
            <h4>
              <FaPhone
                size={20}
                style={{ color: '#ffffff', marginRight: '2rem' }}
              />
              (609) 890-0117
            </h4>
          </div>
          <div className="email">
            <a href="mailto:info@cnwconsultants.com">
              <h4 id="email">
                info@cnwconsultants.com
              </h4>
            </a>
          </div> */}
          {/* <div className="location">
            <h4>
              <FaSearchLocation
                size={20}
                style={{
                  color: '#ffffff',
                  marginRight: '2rem',
                }}
              />
              Cream Ridge, NJ
            </h4>
          </div> */}
        </div>
        <div className='right'>
          {/* <div className="email">
            <a href="mailto:info@cnwconsultants.com">
              <h4 id="email">info@cnwconsultants.com</h4>
            </a>
          </div>

          <div className="phone">
            <h4>
              <FaPhone
                size={20}
                style={{ color: '#ffffff', marginRight: '2rem' }}
              />
              (609) 890-0117
            </h4>
          </div> */}
          <a
            href='https://cnwconsultants.com/wpautoterms/privacy-policy/'
            target='_blank'
            rel='noreferrer'
          >
            <div className='legal-small'>Privacy Policy</div>
          </a>
          <a
            href='https://cnwconsultants.com/wpautoterms/terms-and-conditions/'
            target='_blank'
            rel='noreferrer'
          >
            <div className='legal-small'>Terms of Service</div>
          </a>

          {/* <h4 id="title">CNW Technologies</h4>
          <a href="https://cnwconsultants.com" target="_blank" rel="noreferrer">
            <span className="tagline">a division of CNW Consultants LLC</span>
          </a> */}
          {/* <div className="social">
            <a href="https://github.com" target="_blank" rel="noreferrer">
              <FaGithub
                size={30}
                style={{ color: '#ffffff', marginRight: '1rem' }}
              />
            </a>
            <a href="mailto:info@cnwconsultants.com">
              <FaEnvelope
                size={30}
                style={{ color: '#ffffff', marginRight: '1rem' }}
              />
            </a>
            <a href="https://linkedin.com" target="_blank" rel="noreferrer">
              <FaLinkedin
                size={30}
                style={{ color: '#ffffff', marginRight: '1rem' }}
              />
            </a>
          </div> */}
        </div>
      </div>
      {/* <div id="copyright">Powered by CNW Consultants</div> */}
      <div className='copyright-container'>
        <a
          href='https://cnwconsultants.com/wpautoterms/privacy-policy/'
          target='_blank'
          rel='noreferrer'
        >
          <div className='legal-big'>Privacy Policy</div>
        </a>

        <div id='copyright'>&copy;2019 - {date} CNW Consultants LLC</div>
        <a
          href='https://cnwconsultants.com/wpautoterms/terms-and-conditions/'
          target='_blank'
          rel='noreferrer'
        >
          <div className='legal-big'>Terms of Service</div>
        </a>
      </div>
    </div>
  );
};

export default Footer;
