import React from 'react';
import { Link } from 'react-router-dom';
import './MainStyles.css';

function Main() {
  return (
    <main className='main'>
      <section id='intro'>
        <h1>Welcome to CNW Technologies</h1>
        <p>
          Materialize the full potential of your company's digital presence with
          our strategic web presence management. In today's dynamic online
          world, a strong and engaging web presence is crucial for businesses
          aiming to stand out. At CNW Technologies, we understand the
          intricacies of digital success and offer one-of-a-kind solutions to
          ensure your brand not only survives but thrives.
        </p>
      </section>

      <section id='our-expertise'>
        <h2>Our Expertise in Web Presence Management</h2>
        <p>
          The team at CNW Technologies brings a wealth of knowledge to the
          table, focusing on every aspect of web presence management. From
          meticulous SEO optimization that boosts your search rankings to
          crafting responsive designs that captivate your audience, we're
          committed to enhancing every facet of your online identity.
        </p>
      </section>

      <section id='comprehensive-solutions'>
        <h2>Comprehensive Solutions for Effective Web Presence</h2>
        <p>
          At [Your Company Name], we go beyond conventional strategies. Our
          comprehensive suite of <strong>web presence management</strong>{' '}
          solutions encompasses more than just design. We believe in active
          maintenance, content optimization, and a holistic approach that
          ensures your digital presence remains compelling and current. Trust us
          to be your partner in achieving and maintaining digital excellence.
        </p>
      </section>

      <section id='collaborate-with-us'>
        <h2>Collaborate with Us for Tailored Solutions</h2>
        <p>
          Experience the difference of collaborative{' '}
          <strong>web presence management</strong> with [Your Company Name]. Our
          approach is not one-size-fits-all; instead, we tailor our solutions to
          meet the unique needs of your business. Partner with us, and together,
          we'll create a digital strategy that aligns seamlessly with your
          goals, ensuring sustained growth and relevance in the ever-evolving
          online landscape.
        </p>
      </section>
    </main>
  );
}

export default Main;
