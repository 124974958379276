import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import './VideoStyles.css';
import GoToTop from './GoToTop';
import logofull from '../assets/logo-full.png';

import spaceVideo from '../assets/space.mp4';

const Video = () => {
  useEffect(() => {
    const video = document.getElementById('video');

    if (video) {
      if (window.innerWidth <= 1280) {
        video.removeAttribute('autoplay');
      } else {
        video.setAttribute('autoplay', 'true');
      }
    }
  }, []);
  return (
    <div className='hero'>
      <div className='vid-container'>
        <video autoPlay loop muted id='video'>
          <source src={spaceVideo} type='video/mp4' />
        </video>
      </div>
      <div className='content'>
        <img src={logofull} className='logo-full-img' alt='' />
        <h1>CNW Technologies</h1>
        <span>a division of CNW Consultants LLC</span>
        <div className='divider'></div>
        <p id='slogan'>Creating Value, Delivering Excellence</p>
        {/* <div className='btns'>
          <Link to='/pricing' className='btn'>
            Website Development
          </Link>

          <Link to='/full-stack-dev' className='btn'>
            Web and Mobile Apps
          </Link>
          <Link to='/training' className='btn'>
            Collaborations
          </Link> */}

        <Link to='/contact' className='btn btn-light'>
          Contact Us
        </Link>
        {/* </div> */}
      </div>
      <GoToTop />
    </div>
  );
};

export default Video;
