import React from 'react';
import Footer from '../components/Footer';
import HeroImageContact from '../components/HeroImageContact';
import Navbar from '../components/Navbar';
// import Form from '../components/Form';

const Contact = () => {
  return (
    <div>
      <Navbar />
      <HeroImageContact
        heading="Contact Us."
        text="Our technologies make the world more manageable for our clients."
      />
      {/* <Form /> */}
      <Footer />
    </div>
  );
};

export default Contact;
