import React from 'react';
import './FullStackStyles.css';

import { Link } from 'react-router-dom';

// import Moon from '../assets/MERN.jpg';
import Pod from '../assets/Coding.webp';
import GoToTop from './GoToTop';

const FullStack = () => {
  return (
    <div className="full-stack">
      <div className="full-stack-left">
        <h1>Turn Your Vision Into an App!</h1>
        <p>
          At CNW Technology, we specialize in turning your unique ideas into
          user-friendly web and mobile apps. Whether you're an individual or a
          business, we're here to transform your concepts into digital reality.
        </p>
        <p>
          From concept to launch, our goal is to exceed your expectations,
          whether you're looking to create a game-changing mobile app, a robust
          web platform, or both.
        </p>
        <p>
          Contact us today, and let's redefine what's possible in the digital
          world.
        </p>
        <Link to="/contact">
          <button className="btn">Contact</button>
        </Link>
        {/* <div className="full-stack-image">
          <img src={Moon} id="mern-img" alt="" />
        </div> */}
      </div>
      {/* <div className="full-stack-left">
        <h1>Our languages and skillsets:</h1>
        <p>
          HTML5 | CSS3 | javaScript | Node.js | Express | React | React Native |
          MongoDB | Github | Cloudinary | AWS
        </p>
        <Link to="/contact">
          <button className="btn">Contact</button>
        </Link>
        <div className="full-stack-image">
          <img src={Moon} id="mern-img" alt="" />
        </div>
      </div> */}
      <div className="full-stack-right">
        <img src={Pod} className="full-stack-img" alt="" />
      </div>
      <GoToTop />
    </div>
  );
};

export default FullStack;
