import React from 'react';
import Footer from '../components/Footer';
import HeroImage from '../components/HeroImage';
import PricingCards from '../components/Pricing';
import Navbar from '../components/Navbar';

const Pricing = () => {
  return (
    <div>
      <Navbar />
      <HeroImage
        heading="Website Design"
        text="CNWC will design you a beautiful, fully functional website and develop it.  For a coded website or a web app, see Full-Stack Developer."
      />
      <PricingCards />
      <Footer />
    </div>
  );
};

export default Pricing;
