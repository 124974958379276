import React, { useState } from 'react';
// import { Link, NavLink } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
// import { FaBars, FaBorderNone, FaTimes } from 'react-icons/fa';
import { FaBars, FaTimes } from 'react-icons/fa';
import './NavbarStyles.css';
import Logo from '../assets/Logo.png';

const Navbar = () => {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);

  const [color, setColor] = useState(false);
  const changeColor = () => {
    if (window.scrollY >= 100) {
      setColor(true);
    } else {
      setColor(false);
    }
  };

  window.addEventListener('scroll', changeColor);

  return (
    <div className={color ? 'header header-bg' : 'header'}>
      <a href='https://cnwconsultants.com' target='_blank' rel='noreferrer'>
        <img
          src={Logo}
          alt='logo'
          className='logo'
          // style={{ width: '200px', paddingLeft: '40px' }}
        />
      </a>
      {/* <img src={Logo} alt="Logo" style={{ width: '140px' }} /> */}
      <ul
        className={click ? 'nav-menu active nav-links' : 'nav-menu nav-links'}
      >
        <li>
          <NavLink
            to='/'
            style={({ isActive }) => ({
              color: isActive ? '#6d9bc3' : 'white',
              // textDecoration: isActive ? 'underline' : 'none',
              //   background: isActive ? 'white' : 'none',
            })}
            end
          >
            Home
          </NavLink>
        </li>
        <li>
          <NavLink
            to='/pricing'
            style={({ isActive }) => ({
              color: isActive ? '#6d9bc3' : 'white',
              // textDecoration: isActive ? 'underline' : 'none',
            })}
          >
            Websites
          </NavLink>
        </li>
        <li>
          <NavLink
            to='/full-stack-dev'
            style={({ isActive }) => ({
              color: isActive ? '#6d9bc3' : 'white',
              // textDecoration: isActive ? 'underline' : 'none',
            })}
          >
            Apps
          </NavLink>
        </li>
        <li>
          <NavLink
            to='/training'
            style={({ isActive }) => ({
              color: isActive ? '#6d9bc3' : 'white',
              // textDecoration: isActive ? 'underline' : 'none',
            })}
          >
            Partnerships
          </NavLink>
        </li>

        <li>
          <NavLink
            to='/contact'
            style={({ isActive }) => ({
              color: isActive ? '#6d9bc3' : 'white',
              // textDecoration: isActive ? 'underline' : 'none',
            })}
          >
            Contact
          </NavLink>
        </li>
      </ul>
      <div className='hamburger' onClick={handleClick}>
        {click ? (
          <FaTimes size={20} style={{ color: '#fff' }} />
        ) : (
          <FaBars size={20} style={{ color: '#fff' }} />
        )}
      </div>
    </div>
  );
};

export default Navbar;
