import React, { Component } from 'react';
import './FsdHeroImageStyles.css';

export class FsdHeroImage extends Component {
  render() {
    return (
      <div className="fsd-hero-img">
        <div className="heading">
          <h1>{this.props.heading}</h1>
          <p>{this.props.text}</p>
        </div>
      </div>
    );
  }
}

export default FsdHeroImage;
