import React from 'react';
import Footer from '../components/Footer';
// import HeroImage from '../components/HeroImage';
import Navbar from '../components/Navbar';
import TpmHeroImage from '../components/TpmHeroImage';
import TrainingSection from '../components/Training';

const Training = () => {
  return (
    <div>
      <Navbar />
      <TpmHeroImage
        heading="Contractual Partnership/Collaboration"
        text="We can help develop your project from wireframe to launch."
      />
      <TrainingSection />
      <Footer />
    </div>
  );
};

export default Training;
