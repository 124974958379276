import React from 'react';
import Footer from '../components/Footer';
import FsdHeroImage from '../components/FsdHeroImage';
import FullStack from '../components/FullStack';
import Navbar from '../components/Navbar';

const FullStackDev = () => {
  return (
    <div>
      <Navbar />
      <FsdHeroImage
        heading="Web and Mobile App Development"
        text="Have an idea for an app?  We offer web and mobile apps for your business or your potential startup!"
      />
      <FullStack />
      <Footer />
    </div>
  );
};

export default FullStackDev;
