import React, { Component } from 'react';
import GoToTop from './GoToTop';
import './TpmHeroImageStyles.css';

export class TpmHeroImage extends Component {
  render() {
    return (
      <div className="tpm-hero-img">
        <div className="heading">
          <h1>{this.props.heading}</h1>
          <p>{this.props.text}</p>
        </div>
        <GoToTop />
      </div>
    );
  }
}

export default TpmHeroImage;
