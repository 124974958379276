import React from 'react';
import { Link } from 'react-router-dom';
import './PricingStyles.css';
import Web from '../assets/Screenshot 2023-10-07 190825.png';
import FamRob from '../assets/Family Robotics.png';
import FutHob from '../assets/FuturisticHobbies.png';
import AllBar from '../assets/Allentown Barber.png';
import RamSol from '../assets/Ramblin Sol.png';
import WanSwe from '../assets/Wandering Sweets.png';
import LeaPet from '../assets/LearnPetCare.png';
import UniWeb from '../assets/University Website.png';
import CnwTech from '../assets/CNW Tech.png';

const Pricing = () => {
  return (
    <div className='pricing'>
      <div className='card-container'>
        <div className='card'>
          <h3>- Informational -</h3>
          <span className='bar'></span>
          <p className='btc'>~$750</p>
          <p>- 3 Pages -</p>
          <p>- Home, About, Contact -</p>
          <p>- Your images, your text -</p>
          <p>- Responsive Design -</p>
          <Link to='/contact' className='btn'>
            Get More Info
          </Link>
          <p className='pageNumber'>1 of 3 &gt;</p>
        </div>
        <div className='card'>
          <h3>- E-Commerce -</h3>
          <span className='bar'></span>
          <p className='btc'>~$1,500</p>
          <p>- Many pages -</p>
          <p>- Shop added -</p>
          <p>- Tie in with Square (usually) -</p>
          <p>- Responsive Design -</p>
          <Link to='/contact' className='btn'>
            Get More Info
          </Link>
          <p className='pageNumber'>&lt; 2 of 3 &gt;</p>
        </div>
        <div className='card'>
          <h3>- Custom Build -</h3>
          <span className='bar'></span>
          <p className='btc'>$ ???</p>
          <p>- Large or intricate sites -</p>
          <p>- Implement SEO strategy -</p>
          <p>- Multi-site tie-ins -</p>
          <p>- Call us to discuss -</p>
          <Link to='/contact' className='btn'>
            Get More Info
          </Link>
          <p className='pageNumber'>&lt; 3 of 3</p>
        </div>
      </div>
      <div className='sample-sites'>
        <h4> Sample Sites:</h4>

        <div className=''>
          <div className='pricing'>
            <ul className='card-container'>
              <li className='card'>
                <a
                  href='https://cnwconsultants.com'
                  target='_blank'
                  rel='noreferrer'
                >
                  <img src={Web} className='thumbnail' alt='' />
                  <h3>CNW Consultants LLC</h3>
                  <p className='pageNumber'>1 &gt;</p>
                </a>
              </li>
              <li className='card'>
                <a
                  href='https://technologies.cnwconsultants.com/'
                  target='_blank'
                  rel='noreferrer'
                >
                  <img src={CnwTech} className='thumbnail' alt='' />
                  <h3>CNW Technologies</h3>
                  <p className='pageNumber'>&lt; 2 &gt;</p>
                </a>
              </li>
              <li className='card'>
                <a
                  href='https://familyrobotics.com'
                  target='_blank'
                  rel='noreferrer'
                >
                  <img src={FamRob} className='thumbnail' alt='' />
                  <h3>Family Robotics</h3>
                  <p className='pageNumber'>&lt; 3 &gt;</p>
                </a>
              </li>
              <li className='card'>
                <a
                  href='https://allentownbarbershop.com'
                  target='_blank'
                  rel='noreferrer'
                >
                  <img src={AllBar} className='thumbnail' alt='' />
                  <h3>Allentown Barbershop</h3>
                  <p className='pageNumber'>&lt; 4 &gt;</p>
                </a>
              </li>
              <li className='card'>
                <a
                  href='https://ramblinsolfarm.com'
                  target='_blank'
                  rel='noreferrer'
                >
                  <img src={RamSol} className='thumbnail' alt='' />
                  <h3>Ramblin' Sol Farm</h3>
                  <p className='pageNumber'>&lt; 5 &gt;</p>
                </a>
              </li>
              <li className='card'>
                <a
                  href='https://wanderingsweets.com'
                  target='_blank'
                  rel='noreferrer'
                >
                  <img src={WanSwe} className='thumbnail' alt='' />
                  <h3>Wandering Sweets</h3>
                  <p className='pageNumber'>&lt; 6 &gt;</p>
                </a>
              </li>
              <li className='card'>
                <a
                  href='https://learnpetcare.com'
                  target='_blank'
                  rel='noreferrer'
                >
                  <img src={LeaPet} className='thumbnail' alt='' />
                  <h3>LearnPetCare.com</h3>
                  <p className='pageNumber'>&lt; 7 &gt;</p>
                </a>
              </li>
              <li className='card'>
                <a
                  href='https://futuristichobbies.com'
                  target='_blank'
                  rel='noreferrer'
                >
                  <img src={FutHob} className='thumbnail' alt='' />
                  <h3>Futuristic Hobbies</h3>
                  <p className='pageNumber'>&lt; 8 &gt;</p>
                </a>
              </li>
              <li className='card'>
                <a
                  href='https://prismatic-sunshine-721a18.netlify.app/'
                  target='_blank'
                  rel='noreferrer'
                >
                  <img src={UniWeb} className='thumbnail' alt='' />
                  <h3>University Website</h3>
                  <p className='pageNumber'>&lt; 9</p>
                </a>
              </li>
            </ul>
            {/* <img src={Web} className="thumbnail" id="jira" alt="" />

            <a
              href="https://cnwconsultants.com"
              target="_blank"
              rel="noreferrer"
            >
              CNW Consultants LLC
            </a> */}
          </div>
          {/* <a href="https://familyrobotics.com" target="_blank" rel="noreferrer">
            Family Robotics
          </a> */}
          {/* <a
            href="https://allentownbarbershop.com"
            target="_blank"
            rel="noreferrer"
          >
            Allentown Barbershop
          </a> */}
          {/* <a href="https://ramblinsolfarm.com" target="_blank" rel="noreferrer">
            Ramblin Sol Farm
          </a> */}
          {/* <a
            href="https://wanderingsweets.com"
            target="_blank"
            rel="noreferrer"
          >
            Wandering Sweets
          </a> */}
          {/* <a href="https://learnpetcare.com" target="_blank" rel="noreferrer">
            Learn Pet Care
          </a> */}
          {/* <a
            href="https://futuristichobbies.com"
            target="_blank"
            rel="noreferrer"
          >
            Futuristic Hobbies
          </a> */}
          {/* <a
            href="https://prismatic-sunshine-721a18.netlify.app/"
            target="_blank"
            rel="noreferrer"
          >
            University Website
          </a> */}
        </div>
      </div>
    </div>
  );
};

export default Pricing;
