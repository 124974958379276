import React from 'react';
import './TrainingStyles.css';

import { Link } from 'react-router-dom';

// import Moon from '../assets/AgileModel-1024x485-1.jpg';
// import Pod from '../assets/OIP.jpg';

const Training = () => {
  return (
    <div className="training">
      <div className="left">
        <h1>Contact us now to discuss your project!</h1>
        <p id="blurb">
          If you're an app or website company looking to outsource a piece of
          the development, we will become an extension of your team.
        </p>
        <p id="blurb">
          We bring a wealth of experience, innovative ideas, and a dedication to
          delivering exceptional results.
        </p>
        <p id="blurb">
          Choose us as your collaborative ally, and together we'll transform
          your vision into a reality that's nothing short of remarkable.
        </p>
        <div className="btn-contain">
          <Link to="/contact">
            <button className="btn">Contact</button>
          </Link>
        </div>
      </div>
      {/* <div className="right">
        <div className="img-container">
          <div className="image-stack top">
            <img src={Moon} className="img" alt="" />
          </div>
          <div className="image-stack bottom">
            <img src={Pod} className="img" id="jira" alt="" />
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default Training;
