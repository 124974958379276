import React, { Component } from 'react';
import './HeroImageStyles.css';
import GoToTop from './GoToTop';

export class HeroImage extends Component {
  render() {
    return (
      <div className="hero-img">
        <div className="heading">
          <h1>{this.props.heading}</h1>
          <p>{this.props.text}</p>
        </div>
        <GoToTop />
      </div>
    );
  }
}

export default HeroImage;
